const captchaMash = () => document.querySelectorAll("input[data-mash]").forEach((input) => {
    input.value = input.dataset.mash + input.dataset.mash
});

captchaMash();

document.querySelectorAll('button[data-button-inquiry]').forEach((btn) => {
    btn.addEventListener('click', () => {
        setTimeout(captchaMash, 3000);
    });
});